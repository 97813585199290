<script setup>
import { defineProps, reactive, onMounted } from 'vue'
import { useConfigStore } from "@/store/config"
import { useToast } from "primevue/usetoast"

import EditorCustom from '../../modules/ckeditor/EditorCustom.vue'

const props = defineProps({
  config: {
    type: String,
    required: true
  },
  description: String,
  title: {
    type: String,
    required: true
  },
})

const configStore = useConfigStore()
const toast = useToast()

const model = reactive({
  content: null,
  loading: true
})

onMounted(() => {
  query(model, props.config)
})

const query = (_model, config) => {
  configStore.show(config).then(({ data }) => {
    if (! data.error) {
      _model.content = data.config.value?.contents || ''
      _model.loading = false
    }
  })
} 

const update = (config) => {
  model.loading = true

  configStore.update(config, { value: { contents: model.content } }).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: `Success (#${config})`, life: 3000})

    model.loading = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

</script>

<template>
  <div class="block-section mb-3">
    <div class="block-header mb-2">
      <span class="block-title">
        <h6>{{ props.title }}</h6>
        <div>{{ props.description }}</div>
      </span>
    </div>
    <div class="block-content">
      <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
        <div class="surface-card p-4 border-round w-full lg:w-10">
          <div class="p-fluid">
            <div class="card">
              <div>
                <editor-custom
                  v-if="model.content !== null"
                  v-model="model.content"

                  :disabled="model.loading"
                  :path="`config/${props.config}`"
                />
              </div>
            </div>
          </div>

          <br/>

          <Button label="Save" class="w-full" :loading="model.loading" @click="update(props.config)" />
        </div>
      </div>
    </div>
  </div>
</template>
